@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

[contenteditable] {
    outline: 0px solid transparent;
}

@layer components {
    .btn-ts {
        @apply inline-block text-white text-xs md:text-lg font-bold py-2 px-4 rounded m-1 p-5 w-24 md:m-4 md:w-32;
    }

    .btn-blue {
        @apply bg-blue-500 hover:bg-blue-700
    }

    .btn-green {
        @apply bg-green-500 hover:bg-green-700
    }

    .btn-red {
        @apply bg-red-500 hover:bg-red-700
    }

    .dropdown {
        @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm md:text-lg rounded-lg w-24 md:w-32;
    }
}