/*From: https://codesandbox.io/s/react-simple-editor-linenumbers-wy240?file=/src/styles.css:0-327 */

.code-editor {
    counter-reset: line;
    /* outline: none;
    padding-left: 60px !important; */
   
   
    /* border: 1px solid #ced4da; */
    /* outline: none;
    padding-left: 60px !important; */
    /* font-family: '"Fira code", "Fira Mono", monospace';
    font-size: 18;
    outline: 0; */
    
  }
  
  .code-editor *[id^='codeArea'] {
    outline: none;
    padding-left: 60px !important;
  }
 
  .code-editor pre {
    padding-left: 60px !important;
  }
  
  .code-editor .editorLineNumber {
    
    position: absolute;
    left: 0px;
    color: #cccccc;
    text-align: right;
    width: 40px;
    font-weight: 100;
  }

  .red-line {
    background-color: lightcoral;
  }
  